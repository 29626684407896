<template>
  <div t-id="accessory-products">
    <sorting-and-filtering
      t-id="order-product-container-sorting-and-filtering"
      :primary-options-loading="accessoryCategoriesLoading"
      :primary-options="accessoryCategoryList"
      :secondary-options="hardwareOptions"
      :selected-primary-filter="selectedCategoryFilter"
      :selected-secondary-filter="selectedDeviceFilter"
      :default-sorting-method="selectedSorting"
      secondary-filter-query-parameter-name="passar-till"
      @set-sorting-method="onSetSortingMethod"
      @set-primary-filter="onSetCategoryFilter"
      @set-secondary-filter="onSetDeviceFilter"
    />
    <section t-id="product-group-accessories" class="accessory-products__category">
      <message-presenter v-if="accessoryAlert" :message-type="accessoryAlert" />
      <b2x-spinner
        v-if="accessoriesLoading"
        t-id="accessory-products-loading-container"
        class="accessory-products__loading-indicator"
        size="large"
      />
      <TransitionGroup
        v-else
        :css="true"
        appear
        tag="div"
        name="fade-zoom"
        class="accessory-products__category-products"
      >
        <div
          t-id="product-listing"
          v-for="product in accessoryProducts"
          :key="product.id"
          :class="`accessory-products__listing ${
            accessoriesDisabled ? 'accessory-products__listing-disabled' : ''
          }`"
        >
          <div class="product-card-container" tabindex="1" @click.prevent="selectProduct(product)">
            <b2x-hardware-product-card
              :t-id="`product-listing--accessory-card__${product.id}`"
              :heading="product.name"
              :subheading="product.brand"
              :href="link"
              :image="getImageUrl(product)"
              :price-heading="accessoryPrice(product)"
            >
              <telia-p slot="colors">{{ product.color }}</telia-p>
            </b2x-hardware-product-card>
          </div>
        </div>
      </TransitionGroup>
      <b2x-paginator
        t-id="order-products-container-pagination"
        default-page-size="15"
        :page-sizes="'[15, 30, 60]'"
        :show-page-size-selector="shouldShowSizeSelector.toString()"
        :list-length="totalAccessoryProductCount"
        @paginationChange="onPaginationChange"
      >
      </b2x-paginator>
    </section>
  </div>
</template>

<script lang="ts">
import { currentLanguage } from "@telia/b2b-i18n";
import { defineComponent } from "vue";
import { translateMixin } from "./locale";
import SortingAndFiltering from "./components/sorting/sorting-and-filtering.ce.vue";
import MessagePresenter from "./components/message-presenter.ce.vue";
import placeholder from "./components/assets/accessories.svg";
import { SortingOptions } from "./components/sorting/sortingUtils";
import { trackAccessoryProductList } from "./utils/gaTracking";
import "@telia/b2x-product-card";

export default defineComponent({
  name: "AccessoryProducts",
  mixins: [translateMixin],
  components: { MessagePresenter, SortingAndFiltering },
  props: {
    accessoriesPage: {
      type: Object,
      default: () => ({}),
    },
    accessoriesDisabled: {
      type: Boolean,
      default: false,
    },
    accessoriesLoading: {
      type: Boolean,
      default: false,
    },
    accessoryCategories: {
      type: Array,
      default: () => [],
    },
    accessoryCategoriesLoading: {
      type: Boolean,
      default: true,
    },
    accessoryAlert: {
      type: String,
    },
    hardwareOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedSorting: SortingOptions.NEWEST,
    selectedCategoryFilter: "",
    selectedDeviceFilter: "",
    previousTotalCount: null,
  }),
  computed: {
    link() {
      return window.location.href;
    },
    accessoryCategoryList() {
      return this.accessoryCategories?.map((ac: any) => ac.accessoryCategory) || null;
    },
    accessoryProducts() {
      return this.accessoriesPage?.content || [];
    },
    shouldShowSizeSelector() {
      return !!this.accessoriesPage?.content?.length;
    },
    totalAccessoryProductCount() {
      if (
        Number.isInteger(this.accessoriesPage?.totalItemCount) &&
        this.previousTotalCount !== this.accessoriesPage?.totalItemCount
      ) {
        return this.accessoriesPage?.totalItemCount;
      }
      return this.previousTotalCount;
    },
  },
  mounted() {
    this.trackProductList();
  },
  methods: {
    selectProduct(product) {
      this.$emit("selected-product", {
        product: product,
      });
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat(currentLanguage(), {
        style: "currency",
        currency: "SEK",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(amount);
    },
    getImageUrl(product) {
      return product.gallery?.mainImage?.srcUrl
        ? product.gallery.mainImage.srcUrl + "?h=250&fit=fill"
        : placeholder;
    },
    accessoryPrice(product) {
      return this.formatCurrency(product.unitPrice);
    },
    onPaginationChange(event) {
      this.$emit("pagination-change", event.detail);
    },
    onSetSortingMethod(selected) {
      this.selectedSorting = selected;
      this.$emit("set-sorting-method", this.selectedSorting);
    },
    onSetCategoryFilter(selectedCategoryFilter) {
      this.selectedCategoryFilter = selectedCategoryFilter;
      this.$emit("set-category-filter", selectedCategoryFilter);
    },
    onSetDeviceFilter(selectedDeviceFilter) {
      this.$emit("set-device-filter", selectedDeviceFilter);
    },
    trackProductList() {
      trackAccessoryProductList(this.accessoryProducts, this.selectedCategoryFilter);
    },
  },
  watch: {
    accessoryProducts: "trackProductList",
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/breakpoints/tokens";
@import "@teliads/components/foundations/spacing/tokens";

.accessory-products {
  &__category {
    margin: $telia-spacing-64 0;
  }

  &__category-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: $telia-spacing-12;
    border-bottom: 1px solid #d0d0d0;
  }

  &__category-description {
    margin-top: $telia-spacing-24;
  }

  &__category-products {
    margin-top: $telia-spacing-24;
    display: grid;
    gap: $telia-spacing-24;
    @media screen and (min-width: $telia-breakpoint-small) {
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: $telia-breakpoint-medium) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $telia-breakpoint-large) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__listing {
    &-disabled {
      opacity: 0.17;
      pointer-events: none;
    }
  }

  &__loading-indicator {
    display: flex;
    justify-content: center;
  }
}

.product-card-container {
  display: inline;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-zoom-enter-active,
.fade-zoom-leave-active {
  transition: opacity 0.1s, transform 0.1s;
}

.fade-zoom-enter,
.fade-zoom-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
