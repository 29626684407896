import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { "t-id": "accessory-products" }
const _hoisted_2 = {
  "t-id": "product-group-accessories",
  class: "accessory-products__category"
}
const _hoisted_3 = {
  key: 1,
  "t-id": "accessory-products-loading-container",
  class: "accessory-products__loading-indicator",
  size: "large"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["t-id", "heading", "subheading", "href", "image", "price-heading"]
const _hoisted_6 = { slot: "colors" }
const _hoisted_7 = ["show-page-size-selector", "list-length"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sorting_and_filtering = _resolveComponent("sorting-and-filtering")!
  const _component_message_presenter = _resolveComponent("message-presenter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sorting_and_filtering, {
      "t-id": "order-product-container-sorting-and-filtering",
      "primary-options-loading": _ctx.accessoryCategoriesLoading,
      "primary-options": _ctx.accessoryCategoryList,
      "secondary-options": _ctx.hardwareOptions,
      "selected-primary-filter": _ctx.selectedCategoryFilter,
      "selected-secondary-filter": _ctx.selectedDeviceFilter,
      "default-sorting-method": _ctx.selectedSorting,
      "secondary-filter-query-parameter-name": "passar-till",
      onSetSortingMethod: _ctx.onSetSortingMethod,
      onSetPrimaryFilter: _ctx.onSetCategoryFilter,
      onSetSecondaryFilter: _ctx.onSetDeviceFilter
    }, null, 8 /* PROPS */, ["primary-options-loading", "primary-options", "secondary-options", "selected-primary-filter", "selected-secondary-filter", "default-sorting-method", "onSetSortingMethod", "onSetPrimaryFilter", "onSetSecondaryFilter"]),
    _createElementVNode("section", _hoisted_2, [
      (_ctx.accessoryAlert)
        ? (_openBlock(), _createBlock(_component_message_presenter, {
            key: 0,
            "message-type": _ctx.accessoryAlert
          }, null, 8 /* PROPS */, ["message-type"]))
        : _createCommentVNode("v-if", true),
      (_ctx.accessoriesLoading)
        ? (_openBlock(), _createElementBlock("b2x-spinner", _hoisted_3))
        : (_openBlock(), _createBlock(_TransitionGroup, {
            key: 2,
            css: true,
            appear: "",
            tag: "div",
            name: "fade-zoom",
            class: "accessory-products__category-products"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accessoryProducts, (product) => {
                return (_openBlock(), _createElementBlock("div", {
                  "t-id": "product-listing",
                  key: product.id,
                  class: _normalizeClass(`accessory-products__listing ${
            _ctx.accessoriesDisabled ? 'accessory-products__listing-disabled' : ''
          }`)
                }, [
                  _createElementVNode("div", {
                    class: "product-card-container",
                    tabindex: "1",
                    onClick: _withModifiers(($event: any) => (_ctx.selectProduct(product)), ["prevent"])
                  }, [
                    _createElementVNode("b2x-hardware-product-card", {
                      "t-id": `product-listing--accessory-card__${product.id}`,
                      heading: product.name,
                      subheading: product.brand,
                      href: _ctx.link,
                      image: _ctx.getImageUrl(product),
                      "price-heading": _ctx.accessoryPrice(product)
                    }, [
                      _createElementVNode("telia-p", _hoisted_6, _toDisplayString(product.color), 1 /* TEXT */)
                    ], 8 /* PROPS */, _hoisted_5)
                  ], 8 /* PROPS */, _hoisted_4)
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })),
      _createElementVNode("b2x-paginator", {
        "t-id": "order-products-container-pagination",
        "default-page-size": "15",
        "page-sizes": '[15, 30, 60]',
        "show-page-size-selector": _ctx.shouldShowSizeSelector.toString(),
        "list-length": _ctx.totalAccessoryProductCount,
        "on:paginationChange": _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPaginationChange && _ctx.onPaginationChange(...args)))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_7)
    ])
  ]))
}