<template>
  <div>
    <div class="multiselect-dropdown-container" t-id="multiselect-dropdown">
      <div class="multiselect-dropdown-inputContainer">
        <telia-text-input
          id="multiselect-dropdown-input"
          t-id="multiselect-dropdown-input"
          @click="onFocus()"
          @focus="onFocus()"
          @focusout="onBlur"
          @keydown="onKeyDown"
          @input="onInput"
        />
        <telia-icon :class="getClassesForArrow()" name="chevron-down" />
      </div>
      <ul
        t-id="multiselect-dropdown-suggestions"
        class="multiselect-dropdown-suggestions"
        @mouseover="onMouseOver()"
        v-if="showSuggestions"
      >
        <li
          v-for="(option, index) in filteredOptions"
          :t-id="`multiselect-dropdown-option-${option.name}`"
          :key="option.value"
          :class="getClassesForSuggestion(index)"
          @mousedown.prevent="clickOption(option)"
        >
          <div>
            <telia-icon v-show="option.checked" size="sm" name="check-bold" />
          </div>
          <span v-html="formattedDisplayNameWithMatchInBold(option)"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MultiselectDropdown",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      highlightedSuggestion: undefined,
      inputText: "",
      showSuggestions: false,
    };
  },
  computed: {
    filteredOptions() {
      if (this.inputText.length === 0) {
        return this.options.slice(0, 100);
      }

      return this.options
        .filter((option) => option.name.toLowerCase().includes(this.inputText.toLowerCase()))
        .slice(0, 100);
    },
  },
  methods: {
    onBlur() {
      this.showSuggestions = false;
      this.clearHighlightedSuggestion();
    },
    onFocus() {
      this.showSuggestions = true;
    },
    onInput(event) {
      this.showSuggestions = true;
      this.inputText = event.target.value;
    },
    onKeyDown(e) {
      switch (e.keyCode) {
        case 27: // escape
          this.showSuggestions = false;
          this.clearHighlightedSuggestion();
          break;
        case 40: // down
          this.showSuggestions = true;
          this.incrementHighlightedSuggestion();
          break;
        case 38:
          this.decrementHighlightedSuggestion();
          break;
        case 13: // enter
          this.selectHighlightedSuggestion();
          break;
      }
    },
    onMouseOver() {
      this.clearHighlightedSuggestion();
    },
    escapeRegExpChars(text) {
      return text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
    },
    formattedDisplayNameWithMatchInBold(option) {
      const displayname = option.name;
      return this.inputText
        ? displayname.replace(
            new RegExp(this.escapeRegExpChars(this.inputText), "ig"),
            (m) => `<strong>${m}</strong>`
          )
        : displayname;
    },
    clickOption(option) {
      this.$emit("selected-option", option.value);
    },
    getClassesForArrow() {
      let classes = ["multiselect-dropdown-arrowDown"];

      if (this.showSuggestions) {
        classes.push("multiselect-dropdown-arrowDown--rotate");
      }

      return classes;
    },
    getClassesForSuggestion(index) {
      let classes = ["multiselect-dropdown-suggestions-suggestion"];

      if (index === this.highlightedSuggestion) {
        classes.push("multiselect-dropdown-suggestions-suggestion--highlighted");
      }

      return classes;
    },
    incrementHighlightedSuggestion() {
      if (this.highlightedSuggestion === undefined) {
        this.highlightedSuggestion = 0;
      } else {
        this.highlightedSuggestion = (this.highlightedSuggestion + 1) % this.filteredOptions.length;
      }
    },
    decrementHighlightedSuggestion() {
      if (this.highlightedSuggestion === undefined || this.highlightedSuggestion === 0) {
        this.highlightedSuggestion = this.filteredOptions.length - 1;
      } else {
        this.highlightedSuggestion--;
      }
    },
    selectHighlightedSuggestion() {
      const option = this.filteredOptions[this.highlightedSuggestion];
      this.clickOption(option);
    },
    clearHighlightedSuggestion() {
      this.highlightedSuggestion = undefined;
    },
  },
});
</script>

<style lang="scss">
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";

.multiselect-dropdown-container {
  position: relative;
}

.multiselect-dropdownButton-container {
  display: flex;
  align-items: center;
  margin-bottom: $telia-spacing-8;
}

.multiselect-dropdown-inputContainer {
  position: relative;
  margin-bottom: $telia-spacing-8;
}

.multiselect-dropdown-arrowDown {
  display: inline-block;
  height: 24px;
  width: 24px;
  padding: 4px;
  line-height: 16px;
  fill: $telia-black;
  margin-right: 12px;
  transition: transform 0.3s ease;
  cursor: pointer;
  pointer-events: none;

  position: absolute;
  top: 12px;
  right: 0;
}

.multiselect-dropdown-arrowDown--rotate {
  transform: rotateX(180deg);
}

.multiselect-dropdown-suggestions {
  position: absolute;
  z-index: 98;
  top: 100%;
  overflow: auto;

  list-style: none;
  margin: 0;
  width: calc(100% - 0.2rem);
  padding: 0;
  color: $telia-black;
  background-color: $telia-white;

  border: 0.1rem solid $telia-purple-500;
  border-top: 0;
  max-height: 33vh;
  border-radius: 0.2rem;
}

.multiselect-dropdown-suggestions-suggestion {
  padding: 1.5rem $telia-spacing-12;
  cursor: pointer;
  display: grid;
  grid-template-columns: 3rem auto;
}

.multiselect-dropdown-suggestions-suggestion:hover {
  background-color: $telia-gray-500;
}

.multiselect-dropdown-suggestions-suggestion--highlighted {
  background-color: $telia-gray-500;
}
</style>
