<template>
  <div class="secondary-filter">
    <div t-id="secondary-filter__dropdown" class="secondary-filter__dropdown">
      <telia-p>
        <strong>{{ t("SortingAndFiltering.SecondaryFilter.CompatibleWith") }}</strong>
      </telia-p>
      <multiselect-dropdown :options="checkableOptions" @selected-option="onChange" />
    </div>
    <div t-id="secondary-filter__selections" class="secondary-filter__selected-container">
      <telia-chip-filter
        v-for="option of selected"
        :t-id="'secondary-filter-' + option"
        :key="option"
        :value="option"
        class="secondary-filter-button"
        :checked="selected.includes(option)"
        @change="onChange($event.target.value)"
      >
        {{ getName(option) }}
      </telia-chip-filter>
      <telia-button
        t-id="secondary-filter__clear-button"
        v-if="selected.length"
        type="button"
        variant="tertiary-purple"
        @click="onClearFilter"
      >
        {{ t("SortingAndFiltering.SecondaryFilter.ClearFilter") }}
      </telia-button>
    </div>
  </div>
</template>
<script>
import MultiselectDropdown from "./multiselect-dropdown.ce.vue";
import { translateMixin } from "../../locale";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SecondaryFilter",
  mixins: [translateMixin],
  components: {
    MultiselectDropdown,
  },
  props: {
    options: { type: Array, default: () => [] },
    queryParameterName: { type: String, default: "" },
  },
  data: () => ({
    selected: [],
  }),
  created() {
    window.addEventListener("single-spa:before-routing-event", this.onRouteChange);
  },
  mounted() {
    this.setActiveFilterByQueryParameter();
  },
  destroyed() {
    window.removeEventListener("single-spa:before-routing-event", this.onRouteChange);
  },
  computed: {
    checkableOptions() {
      return this.options.map((option) => ({
        ...option,
        checked: this.selected.includes(option.value),
      }));
    },
    suggestionOptions() {
      return this.options.map((option) => option.name);
    },
  },
  methods: {
    getName(value) {
      return this.options.find((option) => option.value === value).name;
    },
    onRouteChange() {
      this.setActiveFilterByQueryParameter();
    },
    setActiveFilterByQueryParameter() {
      if (this.queryParameterName) {
        const option = this.getDefaultSelectionFromQueryString();
        if (option) {
          this.setDefaultSelection(option);
        }
      }
    },
    getDefaultSelectionFromQueryString() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(this.queryParameterName);
    },
    setDefaultSelection(option) {
      if (this.options.some((o) => o.value === option)) {
        this.selected = [];
        this.onChange(option);
      } else {
        this.onClearFilter();
      }
    },
    onChange(value) {
      const index = this.selected.indexOf(value);

      if (index < 0) {
        this.selected.push(value);
      } else {
        this.selected.splice(index, 1);
      }

      this.$emit("selected-options", this.selected);
    },
    onClearFilter() {
      this.selected = [];
      this.$emit("selected-options", this.selected);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.secondary-filter {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: $telia-spacing-24;
  align-items: start;

  &__dropdown {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $telia-spacing-12;
    align-items: center;
  }

  &__selected-container {
    margin-top: $telia-spacing-8;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__selected-container > * {
    margin: 0 $telia-spacing-4 $telia-spacing-8 $telia-spacing-4;
  }
}
</style>
