<template>
  <section class="sorting-and-filtering-container">
    <div class="sorting-and-filtering-primary">
      <div class="sorting-and-filtering-filter-wrapper">
        <primary-filter
          v-if="primaryOptions && primaryOptions.length && !primaryOptionsLoading"
          :options="primaryOptions"
          @selected-option="$emit('set-primary-filter', $event)"
        />
        <telia-skeleton
          v-if="primaryOptionsLoading"
          t-id="sorting-and-filtering__skeleton-primary"
          class="sorting-and-filtering__skeleton-primary"
        />
      </div>
      <sorting-selector
        t-id="order-product-container-sorting-selector"
        class="sorting-and-filtering__sorting-selector"
        @set-sorting-method="onSetSortingMethod"
        :default-method="defaultSortingMethod"
      />
    </div>
    <div class="sorting-and-filtering__right-side">
      <secondary-filter
        v-if="secondaryOptions && secondaryOptions.length"
        :options="secondaryOptions"
        :query-parameter-name="secondaryFilterQueryParameterName"
        @selected-options="$emit('set-secondary-filter', $event)"
      />
      <telia-skeleton
        v-if="!secondaryOptions"
        t-id="sorting-and-filtering__skeleton-secondary"
        class="sorting-and-filtering__skeleton-secondary"
      />
    </div>
  </section>
</template>
<script>
import PrimaryFilter from "./primary-filter.ce.vue";
import SortingSelector from "./sorting-selector.ce.vue";
import SecondaryFilter from "./secondary-filter.ce.vue";
import { translateMixin, translateSetup } from "../../locale";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SortingAndFiltering",
  mixins: [translateMixin],
  components: {
    PrimaryFilter,
    SecondaryFilter,
    SortingSelector,
  },
  props: {
    primaryOptionsLoading: {
      type: Boolean,
      default: false,
    },
    defaultSortingMethod: { type: String, required: true },
    primaryOptions: { type: Array, default: () => [] },
    secondaryOptions: { type: Array, default: () => [] },
    secondaryFilterQueryParameterName: { type: String, default: "" },
  },
  data: () => ({
    selectedPrimaryFilter: "",
  }),
  async created() {
    await translateSetup();
  },
  methods: {
    onSetSortingMethod(sortingMethod) {
      this.trackSortingMethod(sortingMethod);
      this.$emit("set-sorting-method", sortingMethod);
    },
    trackSortingMethod(sortingMethod) {
      let payload = {
        cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
        cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
      };

      analytics.trackEvent(
        analytics.category.SALESFLOW,
        analytics.action.CLICK,
        sortingMethod,
        0,
        Object.keys(payload).map((key) => ({
          type: key,
          value: payload[key],
        }))
      );
    },
  },
});
</script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

@media screen and (min-width: 768px) {
  .sorting-and-filtering-primary {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-bottom: $telia-spacing-24;
  }
}

@media not screen and (min-width: 768px) {
  .sorting-and-filtering-primary {
    margin-bottom: $telia-spacing-24;
  }

  .sorting-and-filtering-filter-wrapper {
    margin-bottom: $telia-spacing-24;
  }
}

.primary-filter-button {
  margin: 0 $telia-spacing-8 0 0;

  @media screen and (min-width: 768px) {
    &--all {
      margin-right: $telia-spacing-24;
    }
  }
}

.secondary-filter-button {
  margin-right: $telia-spacing-8;
}

.sorting-and-filtering__sorting-selector {
  margin-bottom: $telia-spacing-12;
}

.sorting-and-filtering__right-side {
  margin-bottom: auto;
}

.sorting-and-filtering__secondary-filter {
  margin-top: $telia-spacing-8;
}

.sorting-and-filtering__skeleton-primary {
  width: 60%;
  height: 4.22rem;
  margin-bottom: $telia-spacing-12;
}

.sorting-and-filtering__skeleton-secondary {
  width: 35%;
  height: 5.6rem;
  margin-bottom: $telia-spacing-12;
}
</style>

<style lang="scss">
.primary-filter-button .tc-selection-button__content {
  justify-content: center;
}

@media not screen and (min-width: 768px) {
  .primary-filter-button .tc-selection-button__content {
    min-width: 10rem;
  }
}
</style>
