<template>
  <div t-id="mybd-order-products-sorting-selector" class="sorting-selector">
    <span>
      {{ t("SortingAndFiltering.Sorting.SortBy") }}
    </span>
    <b2x-inline-dropdown
      t-id="mybd-order-products-sorting-selector-dropdown"
      :options="JSON.stringify(options)"
      :value="sortingMethod"
      @optionSelected="onChange($event.detail)"
    />
  </div>
</template>

<script>
import { SortingOptions } from "./sortingUtils";
import { translateMixin } from "../../locale";
import "@telia/b2x-inline-dropdown";
import { defineComponent } from "vue";

export default defineComponent({
  name: "sorting-selector",
  mixins: [translateMixin],
  props: {
    defaultMethod: { type: String, required: true },
  },
  data() {
    return {
      sortingMethod: this.defaultMethod,
      options: Object.keys(SortingOptions).map((option) => ({
        label: this.t(`SortingAndFiltering.Sorting.${SortingOptions[option]}`),
        value: option,
        disabled: false,
        selected: this.sortingMethod === option,
      })),
    };
  },
  methods: {
    onChange(value) {
      this.sortingMethod = value;
      this.$emit("set-sorting-method", this.sortingMethod);
    },
  },
});
</script>

<style lang="scss" scoped>
.sorting-selector {
  display: flex;
  align-items: center;
  text-align: right;
  margin-bottom: auto;
  min-height: 45px;
  & span {
    line-height: 2.4em;
  }
}
.sorting-selector * {
  margin-left: 2px;
  text-align: initial;
  min-height: 45px;
}
</style>
